<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items"
  :querySearch="getListExcelReport"
  isHiddenTableCount
  isHiddenPageCount
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ReportExcel',
  data () {
    return {
      headers: [
        { value: 'file_name', text: this.$t('nameDoc'), sortable: true },
        { value: 'created_at', text: this.$t('createDate'), sortable: true },
        { value: 'event', text: this.$t('save'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          // eslint-disable-next-line camelcase
          action: async ({ token, file_name }) => {
            await this.saveExcelReportDocument({
              token,
              file_name,
              format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
          },
          color: 'blue',
          name: 'mdi-file-upload',
          tooltip: 'tooltip.download',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listExcelReport,
      isLoading: state => state.report.isLoading
    })
  },
  methods: {
    ...mapActions(['getListExcelReport', 'saveExcelReportDocument'])
  }
}
</script>
